/* Home.css */

.home {
    background-color: #f6e6e1;
    overflow: auto;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 115px;
    padding: 0 50px;
    box-sizing: border-box;
}

.no-underline {
    text-decoration: none;
}

.header-left {
    display: flex;
    align-items: center;
}

.logo {
    height: 80px;
}

.site-name {
    margin-left: -10px;
    font-family: 'Pacifico', cursive;
    font-size: 50px;
    color: #f87b45;
}

.header-right a {
    margin-right: 20px;
    text-decoration: none;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-left: 30px;
}

.content {
    display: flex;
    justify-content: space-between;
    margin: 0 50px;
}



.first-image {
    width: 35%;
    height: auto;
    margin-right: auto;
}

.text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin-left: auto;
}

.text-small {
    font-family: 'Inter', sans-serif;
    color: #7f7f7f;
    font-size: 13px;
    margin-left: 30px;
    letter-spacing: 4px;
    /* adjust as needed */
    line-height: 1.5;
    /* adjust as needed */
    font-weight: bold;
}

.text-large {
    font-family: 'Poppins', sans-serif;
    color: black;
    font-size: 82px;
    margin-top: 10px;
}

.text-medium {
    font-family: 'Inter';
    color: black;
    font-size: 20px;
    margin-top: -20px;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.second-image {
    width: 80%;
    height: auto;
    margin-top: -50px;
}

.key-features-section {
    background-color: black;
    height: 1900px;
    width: 100%;

}

.key-features-section-1 {
    background-color: black;
    height: 100px;
    width: 100%;

}

.key-features-header {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 72px;
    text-align: center;
    margin-top: 30px;
}

.key-features-subheader {
    color: #f87b45;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    margin-top: -30px;
    margin-bottom: 30px;
}

.box3D {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 290px;
    width: 20%;
    height: 50%;
    /* adjust as needed */
    transform-style: preserve-3d;
    animation: rotate 60s linear infinite;
    margin-top: 400px;

}

@keyframes rotate {
    0% {
        transform: perspective(1000px) rotateY(-360deg);
    }

    100% {
        transform: perspective(1000px) rotateY(0deg);
    }
}

.box3D span {
    position: absolute;
    top: 0;
    Left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(calc(var(--i) * 45deg)) translateZ(400px);
}

.box3D span img {
    position: absolute;
    top: 0;
    Left: 0;
    width: 80%;
    height: auto;
    object-fit: cover;
}


.comparison-section {
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: 20px;
}

.comparison-column-1 {
    width: 40%;

    text-align: right;
    margin-left: auto;
    margin-right: auto;
}

.comparison-column-2 {
    width: 40%;

    text-align: left;
    margin-left: auto;
    margin-right: auto;

}


.comparison-title {
    color: #7f7f7f;
    font-size: 15px;
    margin-top: -130px;
    letter-spacing: 4px;
}

.comparison-subtitle {
    color: #f87b45;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    margin-top: 20px;
}

.comparison-text {
    font-size: 18px;
    font-family: 'Poppins', cursive;
    margin-top: -20px;
}

.third-image {
    width: 70%;
    height: auto;
    margin-top: 340px;


}

.forth-image {
    width: 70%;
    height: auto;
    margin-top: -350px;
    margin-bottom: -300px;

}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 50px;
    box-sizing: border-box;
    background-color: white;

}

.footer-left,
.footer-middle,
.footer-middle-right,
.footer-right {
    width: 25%;
    /* This may need to be adjusted based on your layout */
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: -10px;
}

.footer-middle {
    margin-right: -65px;

    /* Move the 2nd column closer to the 3rd column */
}

.footer-middle-right {
    margin-left: -125px;
    margin-right: 100px;
    /* Move the 3rd column closer to the 2nd column */
}

.footer-left h2,
.footer-left p {
    font-family: 'Poppins', sans-serif;
    color: black;
    margin-bottom: 10px;
    margin-top: 5px;
}

.footer-left h2 {
    font-size: 30px;

}

.footer-left p {
    font-size: 15px;
}

.store-icons,
.social-icons {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.footer-middle a,
.footer-middle-left a,
.footer-middle-right a {
    text-decoration: none;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: -250px;
}

.social-icons {
    margin-top: auto;
    justify-content: center;
    /* This should center the icons in their container */
}

/* ... Existing styles ... */

@media (max-width: 1024px) {
    header {
        height: 90px;
        padding: 0 20px;
    }

    .logo {
        height: 60px;
    }

    .site-name {
        font-size: 40px;
    }

    .header-right a {
        margin-right: 10px;
        font-size: 14px;
        margin-left: 20px;
    }

    .content {
        margin: 0 20px;
        flex-direction: column;
        align-items: center;
    }

    .first-image {
        width: 90%;
    }

    .text-section {
        width: 100%;
        text-align: center;
        margin: 20px 0;
    }

    .text-large {
        font-size: 50px;
    }

    .text-medium {
        font-size: 16px;
        margin-top: -15px;
    }

    .image-container {
        flex-direction: column;
    }

    .second-image {
        width: 90%;
        margin-top: 0;
    }

    .key-features-header {
        font-size: 50px;
    }

    .key-features-subheader {
        font-size: 16px;
    }

    .box3D {
        width: 60%;
        margin-top: 200px;
    }

    .comparison-section {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .comparison-column-1,
    .comparison-column-2 {
        width: 80%;
    }

    .third-image,
    .forth-image {
        width: 90%;
        margin-top: 200px;
    }

    .footer {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .footer-left,
    .footer-middle,
    .footer-middle-right,
    .footer-right {
        width: 100%;
        margin: 20px 0;
        align-items: center;
        text-align: center;
    }

    .footer-middle,
    .footer-middle-right {
        margin: 0;
    }

    .footer-middle a,
    .footer-middle-left a,
    .footer-middle-right a {
        margin-left: 0;
    }
}