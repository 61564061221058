/* Home.css */

.home {
    background-color: #f6e6e1;
    overflow: auto;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 115px;
    padding: 0 50px;
    box-sizing: border-box;
}

.no-underline {
    text-decoration: none;
}

.header-left {
    display: flex;
    align-items: center;
}

.logo {
    height: 80px;
}

.site-name {
    margin-left: -10px;
    font-family: 'Pacifico', cursive;
    font-size: 50px;
    color: #f87b45;
}

.header-right a {
    margin-right: 20px;
    text-decoration: none;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-left: 30px;
}

.content {
    display: flex;
    justify-content: space-between;
    margin: 0 50px;
}

.second-image {
    width: 70%;
    height: auto;
    margin-top: -50px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 50px;
    box-sizing: border-box;
    background-color: white;
    margin-top: -30px;
}

.footer-left,
.footer-middle,
.footer-middle-right,
.footer-right {
    width: 25%;
    /* This may need to be adjusted based on your layout */
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: -10px;
}

.footer-middle {
    margin-right: -65px;

    /* Move the 2nd column closer to the 3rd column */
}

.footer-middle-right {
    margin-left: -125px;
    margin-right: 100px;
    /* Move the 3rd column closer to the 2nd column */
}

.footer-left h2,
.footer-left p {
    font-family: 'Poppins', sans-serif;
    color: black;
    margin-bottom: 10px;
    margin-top: 5px;
}

.footer-left h2 {
    font-size: 30px;

}

.footer-left p {
    font-size: 15px;
}

.store-icons,
.social-icons {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.footer-middle a,
.footer-middle-left a,
.footer-middle-right a {
    text-decoration: none;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: -250px;
}

.social-icons {
    margin-top: auto;
    justify-content: center;
    /* This should center the icons in their container */
}